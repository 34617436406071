import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export default function OssDisclaimer() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(oss/oss.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: data.allMarkdownRemark.edges[0].node.html,
      }}
    />
  )
}
