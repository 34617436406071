import React from "react"
import Layout from "../components/common/layout"
import OssDisclaimer from "../components/oss/ossDisclaimer"
import OssLicenses from "../components/oss/ossLicenses"

const title = `Open-Source`

const OssLicensesPage = () => (
  <Layout title={title} >
    <OssDisclaimer />
    <OssLicenses />
  </Layout>
)

export default OssLicensesPage
